.sidebar .nav-dropdown.open .nav-link {
  color: #cc2026;
  border-left: 0;
  /* background-color: silver; */
}
.sidebar .nav-dropdown.open .nav-link:hover {
  color: white !important;
  border-left: 0;
}
.sidebar .nav-link.active {
  /* background-color: #b3b3b3; */
}
