/* .navbar {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  
  padding-top: 20px;
  padding-bottom: 0px;
  z-index: 1;
} */
/* .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: -12px;
  list-style: none;
} */
.logoNav .active {
  border-bottom: none !important;
  height: 75px;
}
.mainpagenavbar {
  color: white;
  /* padding-left: 30px; */
  margin-left: 29px;
  padding-top: 20px;
  /* margin-bottom: -17px; */
  text-decoration: inherit !important;
  /* letter-spacing: 0.01em !important; */

  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.35em;
  text-align: center;
  letter-spacing: 0.01em;
  /* color: white; */
}
/* .navbar .active {
  border-bottom: 3px solid rgb(69, 177, 55) !important;
  height: 70px;
} */

.mainpagenavbar:hover {
  color: #b3b3b3;
}

.mainpagenavbar .active {
  color: #b3b3b3 !important;

  /* border-bottom: 3px solid rgb(69, 177, 55) !important; */
}
/* .mainpagenavbar .active::before {
  background-color: rgb(69, 177, 55);
}

.mainpagenavbar :focus {
  border-bottom: 3px solid rgb(69, 177, 55) !important;
} */

.mainpagenavbar :hover::before,
.mainpagenavbar :focus::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.homenavbarfirst .navbar_navigation {
  color: white;
  padding-right: 132px;
  font-weight: bold;
  font-size: 25px;
  padding-left: 10px;
}
.navbtn {
  width: 90px !important;
  display: 'flex';

  max-width: 280px;
  margin-top: 15px;
  text-align: center;
  color: white;
  /* background-color: #2faded; */
  border: 1px solid rgb(69, 177, 55);
  border-radius: 4px;
  margin-left: 15px;
}
.navbtn:hover {
  background-color: white !important;
  border-color: white !important;
  color: #000 !important;
  /* border-bottom: 3px solid red !important; */
}
.navbtn:active {
  background-color: white !important;
  border-color: white !important;
  color: #000 !important;
  /* border-bottom: 3px solid red !important; */
}
.navbtn:focus {
  /* border-color: rgb(69,177,55)!important; */
  /* background-color: rgb(69,177,55) !important; */

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}

.homenavbarfirst .navbar-light .navbar-toggler {
  /* color: white; */
  border-color: white;
  color: green;
}
.homeimage {
  /* margin-left: 82px; */
  width: 220px;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media (max-width: 415px) and (min-width: 300px) {
  /* .homeimage {
    margin-left: -16px;
  } */
}
@media (max-width: 991px) and (min-width: 700px) {
  .navbtn {
    margin-left: 46%;
    margin-top: 10px;
  }
}
@media (max-width: 669px) and (min-width: 200px) {
  .navbtn {
    margin-left: 44%;
    margin-top: 10px;
  }
}
.homepagenavbar {
  color: white;
  text-decoration: blink;
  padding-left: 15px;
  /* margin-top: -8px !important; */
  font-family: 'Roboto', sans-serif;
  /* line-height: 1.35em; */
  text-align: center;
  letter-spacing: 0.05em;
    font-weight: 500;
    font-size: 16px;

  
}
.homenavbar {
  margin-top: 19px !important;
  font-size: 14px !important;
  padding-left: 10px;
}
.homepagenavbar:hover {
  color: #b3b3b3;
  text-decoration: blink;
  /* border-bottom: 3px solid red; */
}

.homepagenavbar:active {
  color: #b3b3b3;
  text-decoration: blink;
}

@media (max-width: 991px) and (min-width: 300px) {
  .homepagenavbar {
    justify-content: center;
    margin: auto;
    display: flex;
  }
  .homeimage {
    /* margin-left: 82px; */
    width: 152px;
  }
}
@media (max-width: 699px) and (min-width: 670px) {
  .navbtn {
    margin-left: 45%;
    margin-top: 10px;
  }
}
@media (max-width: 500px) and (min-width: 450px) {
  .homeimage {
    /* margin-left: 82px; */
    width: 160px;
  }
}
@media (max-width: 450px) and (min-width: 300px) {
  .homeimage {
    /* margin-left: 82px; */
    width: 150px;
  }
}
@media (max-width: 700px) and (min-width: 501px) {
  .homeimage {
    /* margin-left: 82px; */
    width: 150px;
  }
}

@media (max-width: 450px) and (min-width: 420px) {
  .homenavbarfirst .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-left: 85%;
    margin-top: -14%;
    color: green;
  }
}

@media (max-width: 419px) and (min-width: 320px) {
  .homenavbarfirst .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-left: 85%;
    margin-top: -19%;
    color: green;
  }
}
@media (max-width: 1021px) and (min-width: 992px) {
  .mainpagenavbar {
    color: white;
    margin-left: 15px;
  }
}
@media (max-width: 1199px) and (min-width: 1021px) {
  .mainpagenavbar {
    color: white;
    margin-left: 15px;
  }
}
.homenavbarfirst {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
}
.homenavbarfirst .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 4px; */
  background-color: #eceff1;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.homenavbarfirst .navbar__link {
  margin: 1em;
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.3em;
  text-indent: 0.3em;
  color: #181818;
  border-bottom: 3px solid transparent;
}

.homenavbarfirst .navbar__link:hover {
  transform: translateY(-2px);
}

/* .homenavbarfirst .navbar__link--active {
  border-bottom: 3px solid rgb(69, 177, 55);
  transition: border-bottom 0.5s ease-in-out;
  height: 73px;
} */
/* @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px){ */
.homenavbarfirst .navbar-collapse {
    /* position: initial !important; */
    top: 100%;
    right: 0;
    max-width: 100% !important;
    width: 100%;
    background-color: #08283c !important;
    background-color: var(--white);
    z-index: 10;
    box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 10%) !important;
    padding: 0px;
    display: none;
    border-radius: 5px;
  
}
/* } */
