.dangerClass {
  border: red solid 1px;
}
.errormessage {
  color: red;
  text-align: left;
  margin-top: unset;
  line-height: unset;
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}
.topupFormGrid .form-group {
  margin-bottom: 1rem;
  width: 50%;
  float: left;
}
.formcntrl {
  font-size: 14px;
  color: '#171818' !important;
}
.datepicker input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #d2d2d2;
  width: 95px;
}
.timepicker {
  background-color: #d2d2d2;
  color: 'black';
  width: 50%;
  height: 31px;
  float: right;
  margin-top: -35px;
}
/*******Table css********/
.fleetAgntselect {
  margin-top: 5px;
}
/* .fleetAgntTblScrol {
  overflow: scroll;
  height: 665px;
  overflow-x: hidden;
  width: 100%;
} */
.fleetAgntTable table {
  border: 1px solid rgb(209, 208, 208);
}
.fleetAgntTable th {
  padding: 0.5rem;
  height: 40px;
  text-align: left;
  vertical-align: middle;
  font-size: 16px;
  color: white;
  font-family: 'Roboto', sans-serif;
}
.fleetAgntTable td {
  vertical-align: baseline;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: capitalize !important;
}
.fleetAgntdriverimg {
  width: 40px;
  border-radius: 50%;
  margin-left: 0px;
}
.fleetrqustvechicleimg {
  width: 50px;
}
.fleetCap {
  text-transform: capitalize;
}
.fleetAgntTblBtn {
  border-color: rgb(168, 19, 23);
  border-radius: 96px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 32px !important;
  text-align: center;
  padding: 0 20px;
  height: 34px;
  font-family: 'Montserrat', sans-serif;
}
.fleetAgntTblBtn:hover {
  background-color: rgb(168, 19, 23);
  border-color: rgb(168, 19, 23);
}
.fleetAgntTblBtn:active {
  background-color: rgb(168, 19, 23);
  border-color: rgb(168, 19, 23);
}
.fleetAgntTblBtn:focus {
  background-color: rgb(168, 19, 23) !important;
  border-color: rgb(168, 19, 23) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(168, 19, 23) !important;
}
.noDataImgfleet {
  width: 50px;
  height: 55px;
}
.noDataImgDivfleet {
  text-align: center;
}
.noDataTextDivfleet {
  text-align: center;
}
/***********************end******************/
.table>:not(:last-child)>:last-child>* {
  border-bottom-color: currentColor;
  /* background-color: rgb(13, 51, 74);
  color: white; */
}
.cmnMainTableHd th {
 background-color: rgb(13, 51, 74);
 color:white;
}

.cmnMainTableHd {
  background-color: rgb(13, 51, 74);
}
.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.fleetagentmodelheading button {
  color: white;
}
.fleetagentmodelheading button:hover {
  color: white;
}
.close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
}

.paginationDiv {
  justify-content: center;
  display: flex;
}

.paginationDiv .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationDiv .pagination > .active > a {
  background-color: #2199e8;
  border-color: #2199e8;
  color: #fff;
}

.paginationDiv .pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.paginationDiv .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2199e8;

  outline: none;
}

.paginationDiv .pagination > li > a,
.pagination > li > span {
  color: #2199e8;
}

.paginationDiv .pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.paginationDiv .pagination > .disabled > a {
  color: #96caec !important;
  cursor: default;
}
.errStyle {
  color: red;
  font-size: 14px;
}
.errormark{
  color: red;
}
.cmnRecordIcon {
  font-size: 15px;
}

.cmnResutText {
  opacity: 0.9;
}

.cmnPageCount {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.9;
}
.commonInputdiv {
  /*margin-top: 6px;*/
}
.commonInput {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.commonInput input {
  padding: 0.5rem 1rem;
  padding-left: 35px;
  /* border: 1px solid #cc2026; */
  outline: none;
  box-shadow: none;
  text-align: left;
  color: black;
  width: 100% !important;
  height: 35px !important;
  /* border-radius: 30px; */
  border: 1px solid #e4e7ea;
}

.commonInput i {
  position: absolute;
}

.cmnSearchIcon {
  margin-top: 10px !important;
  margin-left: 15px !important;
  min-width: 40px;
  color: #ced4da;
}

.commonFilterDiv {
  width: 100% !important;
  margin-top: 0px !important;
}

.commonFilterSelect {
  width: 100% !important;
  height: 35px;
  background-color: white;
  border: 1px solid #e4e7ea;
  /*margin-top: -4px;*/
}

.cmnFilterForm.form-control {
  width: 245px;
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
