// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,900&display=swap");
