.ud-footer {
  background: var(--heading-color);
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ud-footer .shape {
  position: absolute;
  z-index: -1;
}

.ud-footer .shape.shape-1 {
  top: 0;
  left: 0;
}

.ud-footer .shape.shape-2 {
  top: 0;
  right: 0;
}

.ud-footer .shape.shape-3 {
  bottom: 0;
  right: 0;
}

.ud-widget {
  margin-bottom: 50px;
}

.ud-widget .ud-footer-logo {
  max-width: 160px;
  display: block;
  margin-bottom: 10px;
}

.ud-widget .ud-footer-logo img {
  width: 100%;
}

.ud-widget .ud-widget-desc {
  font-size: 15px;
  line-height: 24px;
  color: #f3f4fe;
  margin-bottom: 30px;
  max-width: 260px;
}

.ud-widget .ud-widget-socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ud-widget .ud-widget-socials a {
  font-size: 16px;
  color: #dddddd;
  margin-right: 20px;
}

.ud-widget .ud-widget-socials a:hover {
  color: var(--primary-color);
}

.ud-widget .ud-widget-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 35px;
  color: var(--white);
}

.ud-widget .ud-widget-links a {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 30px;
  color: #f3f4fe;
}

.ud-widget .ud-widget-links a:hover {
  color: var(--primary-color);
  padding-left: 10px;
}

.ud-widget .ud-widget-brands {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.ud-widget .ud-widget-brands a {
  display: block;
  max-width: 120px;
  margin-bottom: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ud-widget .ud-widget-brands a {
    max-width: 100px;
  }
}

.ud-footer-bottom {
  padding: 35px 0;
  border-top: 1px solid rgba(136, 144, 164, 0.43);
}

.ud-footer-bottom .ud-footer-bottom-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
          
}
.ud-footer-bottom .ud-footer-bottom-left li{
  float:right;
  margin:0 8px;
}
.ud-footer-bottom .ud-footer-bottom-left p{
  float:left;
}

@media (max-width: 767px) {
  .ud-footer-bottom .ud-footer-bottom-left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.ud-footer-bottom .ud-footer-bottom-left a {
  font-weight: normal;
  font-size: 15px;
  color: #f3f4fe;
  margin-right: 30px;
  display: inline-block;
}

@media (max-width: 767px) {
  .ud-footer-bottom .ud-footer-bottom-left a {
    margin: 0px 8px 8px;
  }
}

.ud-footer-bottom .ud-footer-bottom-left a:hover {
  color: var(--primary-color);
}

.ud-footer-bottom .ud-footer-bottom-center {
  text-align: right;
  font-weight: normal;
  font-size: 15px;
  color: #f3f4fe;
  
}

.ud-footer-bottom .ud-footer-bottom-center a {
  color: var(--primary-color);

  
}

.ud-footer-bottom .ud-footer-bottom-right a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .ud-footer-bottom .ud-footer-bottom-right {
    text-align: center;
    margin-top: 10px;
  }
}