.cardMainlogin {
  width: 30rem;
  float: right;
  /* margin-top: 180px; */
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: #f3f2f2;
  margin-top: 30px;
}
/* .cardhead{
    text-align: center;
    font-size: xx-large;
} */
.loginbutton {
  width: 185px;
  display: "flex";
  /* background-color: #3faded; */
  border-color: rgb(69, 177, 55) !important;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-align: center;
}
.loginbutton:hover {
  background-color: rgb(69, 177, 55);
  border-color: rgb(69, 177, 55);
}
.loginbutton:active {
  background-color: rgb(69, 177, 55);
  border-color: rgb(69, 177, 55);
}
.loginbutton:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}
.btnAlign {
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin-top: 20px; */
}
.heading1 {
  margin-top: 218px;
  color: white;
}
.heading2 {
  margin-top: 20px;
  color: white;
}

.heading3 {
  margin-top: 15px;
  color: white;
}
.bgcolorLogin {
  /* background-image: url('../../assets/img/rydz web home bg1.jpg'); */
  /* float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto; */
  /* padding-top: 220px; */
  /* padding-bottom: 120px; */
  /* padding-top: 75px;
  padding-bottom: 75px; */
  /* background-color: white !important; */
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #05334c !important;
  /* padding-bottom: calc(100% * 3 / 4); */
  /* height: 100vh; */
}
.main_columLogin {
  justify-content: center;
  align-items: center;
  display: flex;
}
.SignInHead {
  text-align: center;
  padding-top: 15px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.CreateAccountText {
  text-align: center !important;
  color: #3faded !important;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.businesspartnerforgot {
  color: #3faded !important;
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-top: 20px;
}
.forgotPswd {
  color: #3faded !important;
  justify-content: right;
  margin-right: 36px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.tcomb {
  color: red !important;
}
/* login image */
img.loginimg {
  width: 75px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  margin-top: 20px !important;
}
.loginHead {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.loginForm .form-control {
  border-radius: 0rem;
}
.loginForm .form-group {
  margin-bottom: 0px;
}

.loginForm .fieldset {
  margin-left: 50px;
  /* margin-right: 40px; */
  color: white;
}
.loginForm .formcntrl {
  font-size: 14px;
  border-radius: 0.25rem;
  background-color: white !important;
  width: 60%;
}
.loginForm .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00000040 !important;
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem #f3f2f2; */
}
.loginForm .errormessage {
  margin-bottom: 0px;
  margin-left: 0px;
}
/* .cardMainlogin {
  background-color: #f3f2f2;
  margin-top: 80px;
} */
@media (max-width: 1199px) and (min-width: 992px) {
  .cardMainlogin {
    width: 45%;
    float: right;
    /* margin-top: 180px; */
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cardMainlogin {
    width: 65%;
    float: right;
    /* margin-top: 180px; */
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) and (min-width: 500px) {
  .cardMainlogin {
    width: 80%;
    float: right;
    /* margin-top: 180px; */
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    /* margin-bottom: 30px; */
    margin-top: unset;
    margin-bottom: unset;
  }
  .loginForm .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media (max-width: 499px) and (min-width: 320px) {
  .cardMainlogin {
    width: 100%;
    float: right;
    /* margin-top:unset; */
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    /* margin-bottom: unset; */
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .loginbutton {
    width: 100px !important;
  }
  .loginForm .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .bgcolorLogin {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .forgotPswd {
    margin-right: 0px;
  }
}
/* ==================================================== */
.cardMainlogin {
  /* width: 55rem; */
  margin: auto;
  background-color: white;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 12% !important;
  margin-left: 15%;
  margin-right: 15%;
}
/* .loginheader {
  
  font-size: 24px;
  color: #151b39;
  margin-top: 15%;
  font-weight: 600 !important;
} */

.loginformlabel {
  font-size: 13px;
  color: #000000b3;
  font-weight: 300 !important;
  /* text-transform: uppercase; */
}
.loginform {
  margin-top: 5%;
}
.loginundelne {
  border-bottom: 2px solid rgb(29, 122, 187);
  margin-top: 5%;
}
/* .contactpagebtn {
  width: 100%;
  margin-top: 15px;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;

  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;

  background-color: rgb(29, 122, 187);
  box-shadow: 1px solid rgb(29, 122, 187);
  border: 1px solid rgb(29, 122, 187) !important;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
} */
.contactpagebtn:hover {
  background-color: rgb(69, 177, 55) !important;
  box-shadow: 1px solid rgb(69, 177, 55) !important;
  border: 1px solid rgb(69, 177, 55) !important;
  color: white;
  border: 1px solid rgb(69, 177, 55) !important;
}
.contactpagebtn:active {
  background-color: rgb(69, 177, 55) !important;
  box-shadow: 1px solid rgb(69, 177, 55) !important;
  border: 1px solid rgb(69, 177, 55) !important;
}
.loginform .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #e4e7ea !important;
  outline: 0;
  box-shadow: 0 0 0 0 #e4e7ea !important;
}
.contactpagebtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0 rgb(69, 177, 55) !important;
}

.loginformnames {
  font-size: 12px;
}
/* .loginforgot {
  font-size: 14px;
  color: #5c6873;
  margin-top: 8%;
  text-align: center;
  font-weight: 300 !important;
  cursor: pointer;
  margin-bottom: 10%;
} */
@media (max-width: 991px) and (min-width: 768px) {
  .cardMainlogin {
    width: 40rem;
    margin-top: 12% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (max-width: 767px) and (min-width: 550px) {
  .cardMainlogin {
    width: 30rem;

    margin-top: 15% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
  .loginimage {
    width: 81%;
    margin-top: 15%;
    margin-bottom: 0%;
    margin-left: 20px;
  }
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 549px) and (min-width: 300px) {
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginimage {
    width: 100%;
    margin-top: 15%;
    margin-bottom: 0%;
    margin-left: 0px;
  }
  .cardMainlogin {
    /* width: 17rem; */

    margin-top: 15% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.docdroppera {
  color: white;
  font-size: 16px;
  /* width: 55%; */
  margin-left: 50px;
  margin-bottom: 10px;
  
}
.loginform .form-control {
  display: block;
  width: 55%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #e4e7ea;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 55px;
}
.loginform .form-group {
  margin-bottom: 15px;
}
.remembermeccheck {
  color: white;
  margin-left: 55px;
}
.loginform .form-check-label {
  margin-bottom: 0;
  margin-left: 10px !important;
  margin-top: 5px !important;
}
.loginforgot {
  font-size: 14px;
  color: white;
  /* margin-top: 8%; */
  margin-left: 50px;
  margin-top: 2%;
  font-weight: 500 !important;
  cursor: pointer;
}
.contactpagebtn {
  width: 55%;
  margin-top: 15px;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;
  background-color: rgb(69, 177, 55) !important;

  border: 1px solid rgb(69, 177, 55) !important;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 50px;
  margin-bottom: 20px;
}
.loginheader {
  font-size: 26px;
  color: white;

  font-weight: 600 !important;
  margin-left: 50px;
  /* margin-bottom: -15px; */
}
.loginimage {
  width: 35%;
  margin-top: 40%;
  margin-bottom: 1%;
  margin-left: 30px;
}
.backgroundscreen {
  background-image: url("../../assets/img/rydz-login-new-image.jpg");
  float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  /* background-color: white; */
  background-position: center center;
  background-size: cover;
  /* padding-bottom: 68px; */
}
.loginfrstImg {
  width: 90%;

  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 15%;
  /* height: 75%; */
}
.logindiv {
  margin-top: 40%;
}
.loginrydz {
  color: white;
  margin-left: 50px;
  margin-bottom: 10px;
}
.loginaccount {
  font-size: 14px;
  color: white;
  /* margin-top: 8%; */
  margin-left: 50px;
  margin-top: 1%;
  margin-bottom: 30px;
  /* font-weight: 500 !important; */
  /* cursor: pointer; */
}
.loginaccounts{
  font-size: 16px;
  color: rgb(69, 177, 55) !important;
  /* margin-top: 8%; */
  padding-left: 5px;
  margin-top: 1%;
  /* font-weight: 500 !important; */
  cursor: pointer;
}